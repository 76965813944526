.header {
  display: flex;
  padding: 20px;
  justify-content: space-between;
}

.status {
  margin-bottom: 8px;
}

.change {
  margin-bottom: 10px;
  .oldValue {
    color: #9e9e9e;
    text-decoration: line-through;

    .key {
      margin-right: 8px;
    }
  }

  .newValue {
    .key {
      margin-right: 8px;
    }
  }
}
