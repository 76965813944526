.splitterVertical {
  padding: 0 4px;
  border-radius: 12px;
  background-color: #f2f2f9 !important;
  border: none !important;
  padding: 6px !important;
}

.splitterHorizontal {
  padding: 0 4px;
  border-radius: 12px;
  background-color: #f2f2f9 !important;
  border: none !important;
  padding: 4px !important;
}

.title {
  margin-top: 8px;
  height: 36px;
  max-width: 600px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: default;
}

.treeParameterGroupWrapper {
  background-color: white;
  border-radius: 0 0 0 12px;
  height: 100%;
}

.itemWrapper {
  overflow: hidden !important;
  height: 100%;
}

.actionWrapper {
  display: flex;
}

.switchWrapper {
  display: flex;
}

.switchTitle {
  display: flex;
  align-items: center;
}

.inputWrapper {
  width: 226px;
}
