@import 'src/styles/colors.module.scss';

.iconButton {
  width: 28px;
  height: 28px;
  min-width: 28px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: none;
  }

  &:disabled {
    svg {
      path {
        fill-opacity: 0.26;
        fill: $disable;
      }
    }
  }
}

.iconButtonWrapper {
  background: #fff;
  border-radius: 3px;
  width: 28px;
  height: 28px;
  min-width: 28px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  //box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);

  &:hover {
    background: none;
  }

  &:disabled {
    background: $gray-inactive;
    opacity: 0.4;
  }
}
