@import 'styles/colors.module.scss';

.radioStyle {
  :global(.Mui-checked) {
    color: $button-link-violet;
  }
}

.formControlWrapper {
  margin: 0;
}
