.rangeWrapper {
  display: flex;
  justify-content: flex-start;
}

.dots {
  margin: 0 16px;
  font-weight: 700;
  align-items: flex-end;
  display: flex;
}