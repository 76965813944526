.root {
  border: none;

  :global(.MuiDataGrid-treeDataGroupingCell) {
    height: 100%;
  }

  :global(.MuiDataGrid-cell) {
    border: none;
    &:focus {
      outline: unset;
    }
  }

  :global(.MuiDataGrid-columnHeaders) {
    border: 0;
  }

  :global(.MuiCheckbox-root) {
    padding: 0 0 0 10px;
  }

  :global(.MuiDataGrid-columnHeaderTitleContainer) {
    justify-content: flex-start !important;
  }

  :global(.MuiDataGrid-row:not(:last-child)) {
    margin-bottom: 2px;
  }
}

.radioWrapper {
  display: flex;
  justify-content: flex-start;
  width: 8vw;
  max-height: 50vh;
}

.infoWrapper {
  padding-top: 20px;
  text-align: center;
}

.sectionName {
  width: fit-content;
  padding-right: 16px;
}

.emptySectionName {
  width: 20%;
  height: 100%;
  padding-right: 16px;
}
