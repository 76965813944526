@import 'styles/colors.module.scss';

.wrapper {
  width: 48px;
  height: 20px;
  background-color: none;
  display: flex;
  align-items: center;
  position: relative;
  margin-left: 3px;
}

.wrapperContentFalseActive {
  width: 48px;
  height: 14px;
  border-radius: 10px;
  background-color: rgba(15, 11, 31, 0.6);
}

.wrapperContentFalseInherited {
  width: 48px;
  height: 14px;
  border-radius: 10px;
  background-color: rgba(15, 11, 31, 0.38);
}

.wrapperContentTrueActive {
  width: 48px;
  height: 14px;
  border-radius: 10px;
  background-color: rgba(123, 104, 238, 0.6);
}

.wrapperContentTrueInherited {
  width: 48px;
  height: 14px;
  border-radius: 10px;
  background-color: rgba(202, 193, 255, 0.6);
}

.toggleButtonGroup {
  position: absolute;
  top: 0;
  left: 0;
}

.toggleButtonActive {
  background-color: #7b68ee !important;
  border-radius: 10px !important;
  width: 20px;
  height: 20px;
  margin: 0 0 0 -3px !important;
  border-color: #7b68ee;

  &:hover {
    background-color: transparent;
  }
}

.toggleButtonInherited {
  background-color: #cac1ff !important;
  border-radius: 10px !important;
  width: 20px;
  height: 20px;
  margin: 0 0 0 -3px !important;
  border-color: #cac1ff;

  &:hover {
    background-color: transparent;
  }
}

.toggleButtonFalse {
  background-color: white !important;
  border-radius: 10px !important;
  width: 20px;
  height: 20px;
  margin: 0 0 0 -3px !important;
  border-color: white;

  &:hover {
    background-color: transparent;
  }
}

.toggleButtonNull {
  border: none;
  width: 20px;
  height: 20px;
  background-color: white !important;
  border-radius: 10px !important;
  margin: 0 0 0 -3px !important;

  &:hover {
    background-color: transparent;
  }
}

.toggleButton {
  border: none;
  width: 20px;
  height: 20px;
  border-radius: 10px !important;
  margin: 0 0 0 -3px !important;

  &:hover {
    background-color: transparent;
  }
}

.innerIconWrapper {
  width: 8px;
  height: 8px;
  display: flex;
}
