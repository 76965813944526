@import 'styles/colors.module.scss';

.sideBar {
  &__list {
    padding: 0;
  }

  &__item {
    padding: 8px 10px;
  }

  &__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 44px;
    width: 44px;
  }

  &__link {
    color: unset !important;
    width: 100%;
    text-decoration: none;
  }
}
