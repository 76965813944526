.row {
  background-color: white;
  font-weight: 400;
  border-bottom: 1px solid rgba(242, 242, 249, 1) !important;
  border-radius: 6px;
  padding-left: 6px;

  min-height: 36px !important;
  max-height: auto !important;
}

.mainRow {
  background-color: #f6f6fa;
  font-weight: 500;
  border-radius: 6px;
  border: 0;

  :global(.MuiDataGrid-cell) {
    padding-left: 6px;
    padding-right: 6px;
  }
}
