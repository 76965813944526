.wrapper {
  display: flex;
  flex-direction: column;
  position: relative;

  .Mui-selected {
    background-color: rgba(123, 104, 238, 0.08);
  }
}

.head {
  :global(.MuiTableCell-head) {
    border-bottom: 1px solid rgba(242, 242, 249, 1);

    &:first-child {
      border-bottom-left-radius: 6px;
    }

    &:last-child {
      border-bottom-right-radius: 6px;
    }
  }
}

.tableRow {
  width: 100%;
  height: 38px;

  :global(.MuiTableCell-body) {
    border-bottom: 1px solid rgba(242, 242, 249, 1);

    &:first-child {
      border-bottom-left-radius: 6px;
    }

    &:last-child {
      border-bottom-right-radius: 6px;
    }
  }
}

.footer {
  :global(.MuiTableCell-footer) {
    border-bottom: none;

    &:first-child {
      border-bottom-left-radius: 5px;
    }

    &:last-child {
      border-bottom-right-radius: 5px;
    }
  }
}

.sortIconShow {
  opacity: 0.6;
}

.sortIconHide {
  opacity: 0;
}
