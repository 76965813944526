@import 'src/styles/colors.module.scss';

.buttonActive {
  background: $button-active !important;
  border: none;
  border-radius: 6px 6px 0 0;
  padding: 7px 20px;
}

.button {
  border: none;
  padding: 7px 20px;
}

.labelActive {
  color: $color-white !important;
}

.label {
  color: $button-text-color;
}
