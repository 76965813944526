.menuWrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.onlyName {
  white-space: normal;
}

.name {
  width: 50%;
  white-space: normal;
  padding-right: 8px;
}

.desc {
  width: 50%;
  white-space: normal;
  padding-left: 8px;
}
