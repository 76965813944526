@import './styles/colors.module.scss';

.app {
  height: 100vh;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background-color: #fff;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(15, 11, 31, 0.38);
  border-radius: 4px;
}
