.row {
  background-color: white;
  border-bottom: 1px solid rgba(242, 242, 249, 1) !important;
  border-radius: 6px;

  min-height: 36px !important;
  max-height: auto !important;
}

.mainRow {
  background-color: #f6f6fa;
  border-radius: 6px;
  border: none;

  min-height: 36px !important;
  max-height: auto !important;
}
