@import 'styles/colors.module.scss';
.wrapper {
  height: calc(100vh - 250px);
}

.head {
  :global(.MuiTableCell-head) {
    border-bottom: 1px solid rgba(242, 242, 249, 1);

    &:first-child {
      border-bottom-left-radius: 6px;
    }

    &:last-child {
      border-bottom-right-radius: 6px;
    }
  }
}

.tableRowWrapper {
  width: 100%;
  height: 38px;

  th {
    padding: 0px;
    font-weight: 500;
  }

  :global(.MuiTableCell-body) {
    border-bottom: 1px solid rgba(242, 242, 249, 1);

    &:first-child {
      border-bottom-left-radius: 6px;
    }

    &:last-child {
      border-bottom-right-radius: 6px;
    }
  }
}

.tableRow {
  width: 100%;
  height: 38px;

  :global(.MuiTableCell-body) {
    border-bottom: 1px solid rgba(242, 242, 249, 1);

    &:first-child {
      border-bottom-left-radius: 6px;
    }

    &:last-child {
      border-bottom-right-radius: 6px;
    }
  }
}
