@import 'styles/colors.module.scss';

.wrapper {
  margin-bottom: 24px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.definitionContent {
  padding: 24px;
  height: 168px;
}

.definitionNameWrapper {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 12px;
}

.projectNameWrapper {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-bottom: 2px;
}

.dividerWrapper {
  height: 20px;
  border-color: rgba(15, 11, 31, 0.08);
}

.regionWrapper {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
