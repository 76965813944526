@import 'styles/colors.module.scss';

.toggleButtonGroup {
  height: 36px;
  border-radius: 6px;
  background: $button-inactive;
}

.toggleButtonActive {
  background: $button-active !important;
  border: none;
  border-radius: 6px !important;
}

.toggleButton {
  border: none;
  border-radius: 6px;
}

.labelActive {
  color: $color-white;
}

.label {
  color: $button-text-color;
}
