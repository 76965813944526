@import '../../../styles/colors.module.scss';

.treeWrapper {
  border: none !important;
  height: 100%;

  :global(.rc-tree-list) {
    height: 100%;
  }

  :global(.rc-tree-list-holder) {
    height: 100% !important;
  }

  :global(.rc-tree-checkbox) {
    position: relative;
    min-width: 16px !important;
    min-height: 16px !important;
    background: url('../../../../public/img/checkbox_outlined.svg') no-repeat center !important;
    margin-right: 8px !important;

    &:after {
      content: '';
      position: absolute;
      min-width: 16px;
      min-height: 16px;
      left: 0;
      top: 0;
      opacity: 0;
      background: url('../../../../public/img/checkbox_filled.svg') no-repeat center;
      transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }
  }

  :global(.rc-tree-checkbox-indeterminate) {
    position: relative;
    min-width: 16px !important;
    min-height: 16px !important;
    background: url('../../../../public/img/checkbox_indeterminate.svg') no-repeat center !important;
    margin-right: 8px !important;

    &:after {
      content: '';
      position: absolute;
      min-width: 16px;
      min-height: 16px;
      left: 0;
      top: 0;
      opacity: 0;
      background: url('../../../../public/img/checkbox_outlined.svg') no-repeat center;
      transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }
  }

  :global(.rc-tree-checkbox.rc-tree-checkbox-checked) {
    &:after {
      opacity: 1;
    }
  }

  :global(.rc-tree-checkbox-disabled) {
    display: none !important;
  }

  :global(.rc-tree-treenode) {
    min-height: 28px !important;
    display: flex;
    align-items: center;
  }

  :global(.rc-tree-switcher) {
    background: none !important;
    min-width: 16px;
  }

  :global(.rc-tree-indent-unit) {
    width: 16px;
  }

  :global(.rc-tree-iconEle) {
    display: none !important;
  }

  :global(.rc-tree-node-content-wrapper) {
    width: 100%;
    display: contents !important;
  }

  :global(.rc-tree-node-selected) {
    background-color: unset;
    box-shadow: none;
    opacity: 1;
    width: 100%;
  }

  :global(.rc-tree-treenode-selected) {
    background-color: rgba(123, 104, 238, 0.08);
    border-radius: 6px;
  }

  :global(.rc-tree-title) {
    display: inline-block;
    width: 100%;
    overflow: hidden;
  }
}

.customizeIcon {
  display: none;
}
