.valueWrapper {
  word-wrap: break-word;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.valueContent {
  display: flex;
  align-items: center;
}

.wrapperTable {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  overflow-y: scroll;
  position: relative;
  box-shadow: none;

  .MuiTableRow-hover {
    cursor: pointer;
    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
    }
  }

  .Mui-selected {
    background-color: rgba(0, 0, 0, 0.08);
  }
}
