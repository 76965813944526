$color-white: #fff;
$color-green: #16bb76;
$color-blue: #278aff;
$color-grey: #333333;
$color-black: #000;
$color-find: #f50;
$gradient-blue: #204999;
$gradient-pink: #b1559f;
$gradient-pink2: #b1559f;

$button-link-violet: #655fb8;
$information: #1976d2;

$select-grey: #efefef;
$blue-primary: rgb(25, 118, 210);
$blue-secondary: #234a99;
$gray-light: #d0d0d0;
$gray-inactive: #a1a1a1;

$disable: #0f0b1f;

$color-background: #f2f2f9;
$light-primary-main: #8121e8;
$list-item-active: #45379d;
$list-icon-fill: #45379d;
$light-text-primary: rgba(15, 11, 31, 0.87);
$light-text-secondary: rgba(15, 11, 31, 0.6);

$button-active: #7b68ee;
$button-text-color: #7b68ee;
$button-inactive: rgba(123, 104, 238, 0.08);

$main-font: 20px 'Roboto', sans-serif;
