@import 'styles/colors.module.scss';

.header {
  background: $color-background;
}

.toolbar {
  justify-content: space-between;
  padding: 0 25px 0 12px;
}
