@import 'src/styles/colors.module.scss';

.wrapper {
  :global(.MuiDataGrid-cell--editable[data-field='dataType']) {
    justify-content: space-between;
  }

  :global(.selectedRow) {
    background-color: rgba(123, 104, 238, 0.08) !important;
  }
}

.root {
  :global(.MuiDataGrid-cell) {
    &:focus {
      outline: unset;
    }
  }
}

.documentWrapper {
  display: flex;
  flex-direction: column;
}

.linkWrapper {
  color: $information;
  word-break: break-all;
}

.linkWrapperInherited {
  color: $gray-inactive;
  word-break: break-all;
}

.infoWrapper {
  padding-top: 20px;
  text-align: center;
}

.mainRow {
  background-color: #f6f6fa;
  font-weight: 500;
  border-radius: 6px;
  border: none;

  min-height: 36px !important;
  max-height: 36px !important;
}
