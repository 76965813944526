.select {
  .MuiOutlinedInput-notchedOutline {
    border-color: '#fff' !important;
  }
}

.notSelectedMenuItem {
  font-style: italic;
  opacity: 0.8;
}

.menuWrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.toolTipDesc {
  width: 50%;
  white-space: normal;
}
