@import './styles/colors.module.scss';

@font-face {
  font-family: 'Roboto';
  src: url('./../public/font/Roboto-Regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Roboto';
  src: url('./../public/font/Roboto-Medium.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Roboto';
  src: url('./../public/font/Roboto-Bold.ttf') format('truetype');
  font-weight: 700;
}

html {
  :global(body) {
    margin: 0;
    background-color: $color-background;
    font: $main-font;
    line-height: 1.5;
    color: $color-black;
    font-size: 16px;
  }
}

:global(a) {
  color: rgb(25, 118, 210);

  &:hover {
    color: rgb(25, 118, 210);
  }
}

:global(.MuiBreadcrumbs-li a) {
  text-decoration: none;
}

:global(.MuiDialogTitle-root) {
  padding-bottom: 20px;
  padding-top: 8px;
}

:global(.MuiDialogContent-root) {
  padding-top: 10px !important;
}

:global(.MuiDialogActions-root) {
  padding-right: 24px;
  padding-left: 24px;
}
