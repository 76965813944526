.toolBarWrapper {
  margin-bottom: 12px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.searchWrapper {
  width: 16%;
  margin-right: 24px;
}