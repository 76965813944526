@import '../../../../../styles/colors.module.scss';

.searchValue {
  color: $color-find;
}

.titleWrapper {
  overflow: hidden;
}

.buttonIcon {
  svg {
    &:hover {
      fill: $button-active;
      fill-opacity: 1;
    }
  }
}

.masterValue {
  font-weight: 700;
  display: flex;
}
