.splitter {
  border-radius: 12px;
  background-color: #f2f2f9 !important;
  border: none !important;
  padding: 8px !important;
}

.wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.reflexWrapper {
  position: unset !important;
}
