.root {
  :global(.MuiDataGrid-cell) {
    &:focus {
      outline: unset;
    }
  }
}

.toolBarWrapper {
  margin-bottom: 12px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 24px;
}

.searchWrapper {
  width: 16%;
}