@import 'src/styles/colors.module.scss';

.dialogContentWrapper {
  display: flex;
  flex-direction: column;
}

.dialogSubtitle {
  font-weight: 500;
  font-size: 1.125rem;
}

.message {
  padding-top: 20px;
  padding-left: 20px;
}

.radioClassWrapper {
  height: 300px;
  border: 1px solid (rgba(0, 0, 0, 0.25));
  border-radius: 5px;
}

.radioClass {
  height: 100%;
  overflow: auto;
  padding-left: 10px;
  height: 100%;
  overflow: auto;
}

.treeClass {
  margin-top: 16px;
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
}

.infoWrapper {
  padding-top: 20px;
  text-align: center;
}

.inputWrapper {
  margin: 16px 0 12px;
}
